const Content = () => {
  return (
    <section
      class="elementor-element elementor-element-c5b5333 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
      data-id="c5b5333"
      data-element_type="section"
    >
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-row">
          <div
            class="elementor-element elementor-element-33379ae elementor-column elementor-col-50 elementor-inner-column  padding-right-ot"
            data-id="33379ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-d5bac8d elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="d5bac8d"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2
                      class="elementor-heading-title elementor-size-default"
                      id="titleEmir"
                    >
                      Regulatory Submission
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-af1a2a9 elementor-widget elementor-widget-text-editor"
                  data-id="af1a2a9"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":1,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                      <p>
                        Our team has good relationship with Regulatory Authority
                        and we are ready to assist customer in obtaining timely
                        Regulatory Approval.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-element elementor-element-ebb27ae elementor-column elementor-col-50 elementor-inner-column padding-left-ot"
            data-id="ebb27ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-9e7a048 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="9e7a048"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2
                      class="elementor-heading-title elementor-size-default"
                      id="titleEmir"
                    >
                      Study Report Writing
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-abd2005 elementor-widget elementor-widget-text-editor"
                  data-id="abd2005"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":1,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                      <p>
                        Pharma Metric Labs provides clients with a clear and
                        in-depth study report according to the ICH-GCP
                        guidelines and ASEAN Guidelines
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-row">
          <div
            class="elementor-element elementor-element-33379ae elementor-column elementor-col-50 elementor-inner-column padding-right-ot"
            data-id="33379ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-d5bac8d elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="d5bac8d"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2
                      class="elementor-heading-title elementor-size-default"
                      id="titleEmir"
                    >
                      Subject Recruitment, Screening & Management
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-af1a2a9 elementor-widget elementor-widget-text-editor"
                  data-id="af1a2a9"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":1,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                      <p>
                        We maintain an active database of volunteers for BA/BE
                        study. We screen our volunteers at an ISO-certified
                        clinical testing laboratory to ensure that the study
                        volunteers meet the inclusion criteria.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-element elementor-element-ebb27ae elementor-column elementor-col-50 elementor-inner-column padding-left-ot"
            data-id="ebb27ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-9e7a048 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="9e7a048"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container padding-bawah-comparative">
                    <h2
                      class="elementor-heading-title elementor-size-default"
                      id="titleEmir"
                    >
                      Comparative Dissolution Testing
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-abd2005 elementor-widget elementor-widget-text-editor"
                  data-id="abd2005"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":1,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                      <p>
                        Assist client on how to ensure the successful of BA/BE
                        study by conducting and analyzing the result of
                        comparative dissolution
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-row">
          <div
            class="elementor-element elementor-element-33379ae elementor-column elementor-col-50 elementor-inner-column padding-right-ot"
            data-id="33379ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-d5bac8d elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="d5bac8d"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2
                      class="elementor-heading-title elementor-size-default"
                      id="titleEmir"
                    >
                      Site Management
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-af1a2a9 elementor-widget elementor-widget-text-editor"
                  data-id="af1a2a9"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":1,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                      <p>
                        We provide services on the development, execution, and
                        supervision of plans, policies, programs and practices
                        that control, protocol, deliver, and enhance the value
                        of data and information assets. Our team will ensure
                        that all necessary data are properly collected and
                        recorded according to the approved clinical study
                        protocol. Each project is supported by our quality
                        management system to ensure the integrity of the data
                        generated from the study.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-element elementor-element-ebb27ae elementor-column elementor-col-50 elementor-inner-column"
            data-id="ebb27ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-9e7a048 elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="9e7a048"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <br />
                    {/* <h2 class="elementor-heading-title elementor-size-default">Solutions</h2> */}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-abd2005 elementor-widget elementor-widget-text-editor padding-left-ot"
                  data-id="abd2005"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":1,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix padding-bullet">
                      <p>
                        <ul>
                          <li>Subject Recruitment & Randomization</li>
                          <li>Subject Condition Standardization</li>
                          <li>Dosing and Sampling Supervision</li>
                          <li>Execution and Supervision of Plans</li>
                          <li>Data Management and Control</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-row">
          <div
            class="elementor-element elementor-element-33379ae elementor-column elementor-col-50 elementor-inner-column padding-right-ot"
            data-id="33379ae"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div class="elementor-column-wrap elementor-element-populated">
              <div class="elementor-widget-wrap">
                <div
                  class="elementor-element elementor-element-d5bac8d elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="d5bac8d"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1,"avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2
                      class="elementor-heading-title elementor-size-default"
                      id="titleEmir"
                    >
                      Microbial Media Procurement
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-af1a2a9 elementor-widget elementor-widget-text-editor"
                  data-id="af1a2a9"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_translatey":{"unit":"px","size":50,"sizes":[]},"":"false","avante_ext_smoove_duration":1,"avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                      <p>
                        Our clients can also buy high-quality microbiological
                        media and microbial stocks from us
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
